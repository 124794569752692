import classNames from 'classnames'
import React, { Component } from 'react'
import '../index.scss'

type Props = {
  isCollapsed: boolean
}

type State = {
  menuOpen: boolean
}

class MenuIcon extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      menuOpen: false,
    }
  }

  render() {
    const { isCollapsed } = this.props
    const isMenuOpen = classNames('navigation-menu-icon', {
      openMenu: !isCollapsed,
    })

    return (
      <div className={isMenuOpen}>
        <div className="nav-menu-bar bar-1" />
        <div className="nav-menu-bar bar-2" />
        <div className="nav-menu-bar bar-3" />
      </div>
    )
  }
}

export default MenuIcon

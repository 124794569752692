import React, { Suspense } from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import LazyLoadingFallback from '~/components/lazy-loading-fallback'
import Auth from '~/containers/auth'
import useLocationChangeHandler from '~/hooks/use-location-change-handler'
import useReduxNavigationHandler from '~/hooks/use-redux-navigation-handler'
import AuthFailure from '~/pages/auth-failure'
import { componentLoader } from '~/utils/component-loader'
import getFeatureFlags from '~/utils/get-feature-flags'

import UnregisteredRoutePathHandler from './unregistered-route-path-handler'

const CartRoutesWrapper = React.lazy(() =>
  componentLoader(() => import('./cart-routes-wrapper'), 3),
)
const Login = React.lazy(() =>
  componentLoader(() => import('~/containers/login'), 3),
)
const MyAccountRoutes = React.lazy(() =>
  componentLoader(() => import('./myaccount'), 3),
)
const OnboardingRoutes = React.lazy(() =>
  componentLoader(() => import('./onboarding'), 3),
)
const MiscRoutesWrapper = React.lazy(() =>
  componentLoader(() => import('./misc-routes-wrapper'), 3),
)

const generateGroupedRoutes = (paths, Component) =>
  paths.filter(Boolean).map((path) => (
    <Route
      path={path}
      key={path}
      element={
        <Suspense fallback={<LazyLoadingFallback />}>
          <Component />
        </Suspense>
      }
    />
  ))

export default function RoutesIndex() {
  const featureFlags = getFeatureFlags()
  useLocationChangeHandler()
  useReduxNavigationHandler()

  return (
    <Routes>
      <Route index element={<Navigate to="myaccount" replace />} />
      <Route
        path="myaccount/*"
        element={
          <Suspense fallback={<LazyLoadingFallback />}>
            <Auth>
              <MyAccountRoutes />
            </Auth>
          </Suspense>
        }
      />

      <Route path="auth/failure" element={<AuthFailure />} />
      <Route path="create-my-account" element={<Navigate to="/login" />} />
      <Route
        path="onboarding/*"
        element={
          <Suspense fallback={<LazyLoadingFallback />}>
            <OnboardingRoutes />
          </Suspense>
        }
      />
      <Route
        path="login"
        element={
          <Suspense fallback={<LazyLoadingFallback />}>
            <Login />
          </Suspense>
        }
      />

      {generateGroupedRoutes(
        [
          'assign-passes',
          'buy',
          'cart',
          'payment',
          'payment/review',
          featureFlags.promotionCodeAutoApplicationEnabled ? 'promocode' : '',
          featureFlags.promotionCodeAutoApplicationEnabled
            ? 'promotions/:key'
            : '',
          'renew',
          'waiver',
        ],
        CartRoutesWrapper,
      )}

      {generateGroupedRoutes(
        [
          'auth-app',
          'cancel-reservation/:id',
          'claim-vouchers',
          'confirm/:email_confirmation_token',
          'forgot-password',
          'loading-profile',
          'redirect',
          'register/confirm',
          'reset-password/:token',
          'return-vouchers',
        ],
        MiscRoutesWrapper,
      )}

      <Route path="*" element={<UnregisteredRoutePathHandler />} />
    </Routes>
  )
}

import React, { useEffect, useState } from 'react'

import Banner, { Left, Right, CloseButton, Text } from '~/components/banner'

import {
  Wrapper,
  MessageIcon,
  Title,
  ANIMATION_SPEED,
} from './flash-message-banner.styles'

import type { Props } from './flash-message-banner.types'

const AUTO_HIDE_DELAY = 4000
const ANIMATION_START_DELAY = 200

const FlashMessageBanner = ({ onDismiss, message }: Props) => {
  const [bannerVisible, setBannerVisible] = useState(false)
  const [autoHideTimeoutID, setAutoHideTimeoutID] = useState(null)

  useEffect(() => {
    setAutoHideTimeoutID(
      setTimeout(() => {
        handleDismiss()
      }, AUTO_HIDE_DELAY + ANIMATION_START_DELAY),
    )

    const setBannerVisibleTimeoutId = setTimeout(() => {
      setBannerVisible(true)
    }, ANIMATION_START_DELAY)

    return () => {
      clearTimeout(setBannerVisibleTimeoutId)

      if (autoHideTimeoutID) {
        clearTimeout(autoHideTimeoutID)
        setAutoHideTimeoutID(null)
      }
    }
  }, [])

  const handleDismiss = () => {
    setBannerVisible(false)
    setTimeout(() => {
      onDismiss()
    }, ANIMATION_SPEED)
    setAutoHideTimeoutID(null)
  }

  if (message) {
    const msgType = message.type === 'error' ? 'alert' : message.type

    return (
      <Wrapper $easeIn={bannerVisible}>
        <Banner msgType={msgType} data-testid="alert-banner">
          <Left>
            <Title>
              <MessageIcon
                $msgtype={message.type}
                glyph={message.options.glyph}
              />
              <Text msgtype={message.type}>{message.text}</Text>
            </Title>
          </Left>
          <Right>
            <CloseButton
              msgtype={message.type}
              onClick={handleDismiss}
              data-testid="flash-message-dismiss"
            />
          </Right>
        </Banner>
      </Wrapper>
    )
  } else {
    return null
  }
}

export default FlashMessageBanner

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { extendSession } from '~/actions/session'
import useInterval from '~/hooks/use-interval'
import useMemoizedSelector from '~/hooks/use-memoized-selector'
import changeLocation from '~/utils/change-location'
import { pathWithoutLocale } from '~/utils/locale'
import SessionCookie, {
  redirectUri,
  shouldRedirectOnSessionExpiry,
  shouldWarnSessionExpiry,
} from '~/utils/session-cookie'

import ExpirationWarningComponent from './expiration-warning.component'

const INTERVAL = 1000

// EGI-826, we no longer show session warnings
const EXPIRY_WARNINGS_ENABLED = false

export default () => {
  const [expiresIn, setExpiresIn] = useState(SessionCookie.expiresIn())
  const location = useLocation()
  const selectorProps = useMemoizedSelector((state) =>
    mapStateToProps(state, {
      location,
    }),
  )
  const dispatchProps = mapDispatchToProps(useDispatch())
  const props = { ...selectorProps, ...dispatchProps }

  useInterval(() => {
    setExpiresIn(SessionCookie.expiresIn())
  }, INTERVAL)

  useEffect(() => {
    if (
      shouldRedirectOnSessionExpiry(props) &&
      SessionCookie.isSessionExpired()
    ) {
      changeLocation(
        `/logout?session_expired=true&redirect_uri=${encodeURIComponent(
          redirectUri(props.isAuthenticated),
        )}`,
      )
    }
  }, [
    expiresIn,
    props.isAuthenticated,
    props.cartHasItems,
    props.hasOnboardingPii,
    props.pathname,
  ])

  return (
    <ExpirationWarningComponent
      expiresIn={Math.max(Math.round(expiresIn / 1000), 0)}
      extendExpiry={props.extendExpiry}
      session={props.session}
      show={EXPIRY_WARNINGS_ENABLED && shouldShowSessionWarning(props)}
    />
  )
}

function shouldShowSessionWarning(props) {
  return (
    shouldWarnSessionExpiry(props) &&
    SessionCookie.isSessionExpirationImminent()
  )
}

function mapStateToProps(state, { location }) {
  const { pathname } = location

  return {
    cartHasItems: state.cart.profiles.length > 0,
    hasOnboardingPii: !!state.onboarding.data.dob,
    isAuthenticated: state.auth.authenticated,
    pathname: pathWithoutLocale(pathname),
    session: state.session,
  }
}

function mapDispatchToProps(dispatch: (...args: Array<any>) => any) {
  return {
    extendExpiry: () => dispatch(extendSession()),
  }
}

import React from 'react'

import { setCurrencyAndLocale } from '~/actions/cart'
import { useAppDispatch } from '~/hooks/use-app-dispatch'

import GlobalHeader from './global-header.component'

import type { ContainerProps } from './global-header.types'
import type { Currency, Locale } from '~/types'

export default function GlobalHeaderContainer(props: ContainerProps) {
  const dispatch = useAppDispatch()

  return (
    <GlobalHeader
      {...props}
      onCurrencyAndLocaleChange={(currency: Currency, locale: Locale) =>
        dispatch(setCurrencyAndLocale(currency, locale))
      }
    />
  )
}

import React from 'react'

import Button from '~/components/button'
import ContentToggle from '~/components/content-toggle-v2'
import Icon from '~/components/icon'
import useFeatureFlags from '~/hooks/use-feature-flags'
import { i18n } from '~/i18n'

import './my-account-submenu.scss'

import type { GuestProfile } from '~/types'

type Props = {
  authenticated: boolean
  onClickLink?: () => any
  profile?: GuestProfile
  validForBenefits: boolean
}

const ListItemLink = ({
  to,
  text,
  onClick,
}: {
  to: string
  text: string
  onClick: (() => void) | null | undefined
}) => (
  <li>
    <Button type="plain" to={to} onClick={onClick}>
      {text}
    </Button>
  </li>
)

const Label = ({ text }: { text: string }) => {
  return (
    <div className="label">
      <Icon glyph="skiier" />
      <div className="text">{text}</div>
    </div>
  )
}

export default function MyAccountSubmenu({
  onClickLink,
  profile,
  authenticated,
  validForBenefits,
}: Props) {
  const { myAccountBenefitsEnabled } = useFeatureFlags()

  return (
    <div className="my-account-submenu" data-testid="my-account-submenu">
      {authenticated ? (
        <div className="toggle">
          <Label
            text={i18n.t(
              'components.default_layout.my_account_submenu.authenticated_label',
              {
                first_name: profile.firstName,
              },
            )}
          />
          <ContentToggle defaultOpen={false}>
            <ul className="links">
              <ListItemLink
                key="myaccount"
                to="/myaccount"
                text={i18n.t('components.default_layout.myaccount_btn')}
                onClick={onClickLink}
              />
              <ListItemLink
                key="payment_details"
                to="/myaccount/payment-details-and-resort-charge"
                text={i18n.t(
                  'components.default_layout.payment_and_resort_charge_btn',
                )}
                onClick={onClickLink}
              />
              {validForBenefits && myAccountBenefitsEnabled && (
                <ListItemLink
                  key="passbenefits"
                  to="/myaccount/passbenefits"
                  text={i18n.t('components.default_layout.pass_benefits_btn')}
                  onClick={onClickLink}
                />
              )}
              <ListItemLink
                key="my_orders"
                to="/myaccount/my-orders"
                text={i18n.t('components.default_layout.orders_btn')}
                onClick={onClickLink}
              />
              <ListItemLink
                key="logout"
                to="/logout"
                text={i18n.t('components.default_layout.sign_out_btn')}
                onClick={onClickLink}
              />
            </ul>
          </ContentToggle>
        </div>
      ) : (
        <Button type="unstyled" to="/myaccount">
          <Label
            text={i18n.t(
              'components.default_layout.my_account_submenu.unauthenticated_label',
            )}
          />
        </Button>
      )}
    </div>
  )
}

/**
 * Renders the 404 page.
 */
import React from 'react'

import LayoutSingle from '~/components/layouts/single'
import NotFoundMessage from '~/components/not-found-message'
import { DefaultTemplate } from '~/components/templates'

type Props = {
  body?: string
}

export default function NotFound({ body }: Props) {
  return (
    <DefaultTemplate>
      <LayoutSingle>
        <NotFoundMessage body={body} />
      </LayoutSingle>
    </DefaultTemplate>
  )
}

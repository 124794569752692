import { datadogRum } from '@datadog/browser-rum'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import fullName from '~/utils/full-name'

import { DATADOG_RUM_CONFIG } from './config'

import type { AppState } from '~/reducers'

// This component initializes and manages the Datadog Real User Monitoring (RUM) functionality
export default function DatadogRum() {
  const profile = useSelector((state: AppState) => state.profile)
  const isAuthenticated = useSelector(
    (state: AppState) => state.auth.authenticated,
  )

  useEffect(() => {
    datadogRum.init(DATADOG_RUM_CONFIG)
    datadogRum.setGlobalContextProperty('team', 'amp')
    datadogRum.startSessionReplayRecording()
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      datadogRum.setUser({
        id: profile.guestId,
        name: fullName(profile.firstName, profile.lastName),
        email: profile.email,
      })
    } else {
      datadogRum.clearUser()
    }
  }, [isAuthenticated, profile])

  return null
}

import React, { Component } from 'react'
import '../index.scss'

import ShoppingBag from '~/graphics/shopping-bag.svg'

const RenderCartCount = ({ count }) => {
  if (count === 0 || !count) {
    return false
  }

  return (
    <div data-testid="cart-count" className="cart-count-badge">
      {count}
    </div>
  )
}

type Props = {
  cartItemCount: number | null | undefined
}

class HeaderCart extends Component<Props> {
  render() {
    const { cartItemCount } = this.props

    return (
      <div id="header-cart-count">
        <div className="cart-icon">
          <img src={ShoppingBag} alt="shopping bag icon" />
          <RenderCartCount count={cartItemCount} />
        </div>
      </div>
    )
  }
}

export default HeaderCart

import { connect } from 'react-redux'

import FraudCheckService from './fraud-check-service.component'

import type { AppState } from '~/reducers'

export function mapStateToProps(state: AppState) {
  return {
    cart: state.cart,
  }
}

export default connect(mapStateToProps)(FraudCheckService)

import React, { Component } from 'react'

import Button from '~/components/button'

import { CurrentGuestWrapper } from './current-guest.styles'
import ProfilePicture from '../../../profile-picture'

import type { GuestProfile } from '../../../../types'

type PictureProps = {
  profile: GuestProfile
  photoAltText: string
  greetingText: string
}

type CurrentGuestProps = {
  currentGuestProfile?: GuestProfile
  myAccountPath?: string
  currentGuestContent?: {
    photoAltText: string
    greetingText: string
  }
}

export class CurrentGuestProfilePicture extends Component<PictureProps> {
  render() {
    const { cloudinary } = this.props.profile
    return (
      <CurrentGuestWrapper data-testid="amp-current-guest">
        <ProfilePicture photo={cloudinary} alt={this.props.photoAltText} />
        <div className="greeting">{this.props.greetingText}</div>
      </CurrentGuestWrapper>
    )
  }
}

const CurrentGuest = ({
  currentGuestProfile,
  myAccountPath,
  currentGuestContent,
}: CurrentGuestProps) => {
  if (currentGuestProfile && currentGuestContent) {
    const currentGuest = (
      <CurrentGuestProfilePicture
        {...currentGuestContent}
        profile={currentGuestProfile}
      />
    )

    if (myAccountPath) {
      return (
        <Button
          type="unstyled"
          data-testid="current-guest"
          className="current-guest-wrapper"
          to={myAccountPath}
        >
          {currentGuest}
        </Button>
      )
    } else {
      return <div className="current-guest-wrapper">{currentGuest}</div>
    }
  } else {
    return null
  }
}

export default CurrentGuest

import classnames from 'classnames'
import React, { Component } from 'react'

import Button from '~/components/button'
import ContentBlock from '~/components/content-block'
import Icon from '~/components/icon'
import { i18n } from '~/i18n'

import { ContactUsButton } from './footer.styles'

import './index.scss'

const PlainButton = (props) => <Button type="plain" {...props} />

const ContactLinks = () => (
  <div className="contact-links">
    <div id="chat-btn-container" />
    <ContactUsButton to={i18n.t('links.contact_us_link.to')} target="_blank">
      <Icon glyph="chevron-right" />
      {i18n.t('links.contact_us_link.text')}
    </ContactUsButton>
  </div>
)

const ContactSection = () => (
  <div className="section contact">
    <ContentBlock className="keep-to-preserve-div">
      {i18n.t('components.footer.content')}
    </ContentBlock>
    <ContactLinks />
  </div>
)

const LegalLinks = () => {
  const handleOneTrustToggle = () => {
    if (!window.OneTrust) return

    window.OneTrust.ToggleInfoDisplay()
  }

  return (
    <div className="legal-links">
      <PlainButton
        to={i18n.t('links.privacy_policy_link.to')}
        target="_blank"
        rel="noopener"
      >
        {i18n.t('links.privacy_policy_link.text')}
      </PlainButton>
      <PlainButton
        to={i18n.t('links.terms_of_use_link.to')}
        target="_blank"
        rel="noopener"
      >
        {i18n.t('links.terms_of_use_link.text')}
      </PlainButton>
      <PlainButton id="ot-sdk-btn" onClick={handleOneTrustToggle}>
        {i18n.t('components.footer.one_trust_cookie_settings')}
      </PlainButton>
    </div>
  )
}

const LegalSection = () => (
  <div className="section legal">
    <LegalLinks />
    <ContentBlock className="keep-to-preserve-div">
      {i18n.t('components.footer.copyright_text')}
    </ContentBlock>
  </div>
)

type Props = {
  flat?: boolean
  className?: string
}

export default class Footer extends Component<Props> {
  render() {
    return (
      <div
        className={classnames(
          'amp-footer',
          {
            flat: this.props.flat,
          },
          this.props.className,
        )}
      >
        <div className="container">
          <ContactSection />
          <LegalSection />
        </div>
      </div>
    )
  }
}

import Button from '../../button'

import React, { Component } from 'react'

import '../index.scss'
import EnglishLogo from '~/graphics/ikon-gold.svg'

const RenderBrandIcon = () => {
  return <img src={EnglishLogo} alt="mountain icon" />
}

type Props = {
  href: string
}

class HeaderLogo extends Component<Props> {
  render() {
    return (
      <div id="header-logo">
        <Button type="unstyled" to={this.props.href} data-testid="header-logo">
          <RenderBrandIcon />
        </Button>
      </div>
    )
  }
}

export default HeaderLogo

import React from 'react'

import { updateCartChanged } from '~/actions/cart'
import ContentBlock from '~/components/content-block'
import Modal from '~/components/modal'
import { useAppDispatch } from '~/hooks/use-app-dispatch'
import { i18n } from '~/i18n'

export default function CartChanged() {
  const dispatch = useAppDispatch()

  return (
    <Modal
      title={i18n.t('pages.cart.cart_changed.title')}
      onClose={() => dispatch(updateCartChanged())}
      actions={{
        primary: {
          type: 'progress',
          onClick: () => dispatch(updateCartChanged()),
          text: i18n.t('pages.cart.cart_changed.close_btn'),
        },
      }}
    >
      <ContentBlock className="keep-to-preserve-div">
        {i18n.t('pages.cart.cart_changed.content')}
      </ContentBlock>
    </Modal>
  )
}

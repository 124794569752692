import styled from 'styled-components'

import { color, font } from '~/styles/utils'

export const SkipToMainContent = styled.a`
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  &:focus {
    ${font('bold')};
    color: #fff;
    background-color: ${color('blueCreek')};
    left: auto;
    top: auto;
    height: auto;
    overflow: auto;
    margin: 20px 40%;
    width: 20%;
    padding: 4px 30px;
    text-align: center;
    font-size: 1.2em;
    z-index: 999;
    text-transform: uppercase;
  }
`

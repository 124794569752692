import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './app'

const node = document.getElementById('root')

if (node == null) {
  throw new Error('React root node is not defined')
}

const root = createRoot(node)
root.render(<App />)

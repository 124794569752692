import { i18n } from '~/i18n'
import { locationWithLocale } from '~/utils/locale'

import type { GuestProfile, Links } from '~/types'

const getNavLinks = (): Links => [
  {
    text: i18n.t('links.shop_passes_link.text'),
    to: i18n.t('links.shop_passes_link.to'),
  },
  {
    text: i18n.t('links.destinations_link.text'),
    to: i18n.t('links.destinations_link.to'),
  },
  {
    text: i18n.t('links.faq_link.text'),
    to: i18n.t('links.faq_link.to'),
  },
]

const getLegalLinks = (): Links => [
  {
    text: i18n.t('links.privacy_policy_link.text'),
    to: i18n.t('links.privacy_policy_link.to'),
  },
  {
    text: i18n.t('links.terms_of_use_link.text'),
    to: i18n.t('links.terms_of_use_link.to'),
  },
]

const baseContent = (): Record<string, any> => ({
  shopPasses: {
    text: {
      mobile: i18n.t('components.default_layout.shop_passes_mobile_btn'),
      desktop: i18n.t('components.default_layout.shop_passes_desktop_btn'),
    },
    to: i18n.t('links.shop_passes_link.to'),
  },
  navigation: {
    links: getNavLinks(),
  },
  bottomLinks: {
    links: getLegalLinks(),
  },
  socialLinks: {
    links: [
      {
        type: 'twitter',
        to: i18n.t('links.twitter_link.to'),
      },
      {
        type: 'instagram',
        to: i18n.t('links.instagram_link.to'),
      },
      {
        type: 'facebook',
        to: i18n.t('links.facebook_link.to'),
      },
    ],
  },
})

type Props = {
  showShopPasses?: boolean
  profile?: GuestProfile
}

export const menuContent = (props: Props) => {
  const profile = props.profile
  const firstName = profile ? profile.firstName : ''
  const { navigation, ...rest } = baseContent()

  if (!props.showShopPasses) delete rest.shopPasses

  return {
    ...rest,
    navigation: {
      links: navigation.links,
    },
    localeLinks: {
      links: [
        {
          text: 'English',
          to: locationWithLocale('en'),
        },
        {
          text: 'FRANÇAIS',
          to: locationWithLocale('fr'),
        },
      ],
    },
    currentGuest: {
      photoAltText: i18n.t(
        'components.default_layout.current_guest.photo_alt_text',
        {
          first_name: firstName,
        },
      ),
      greetingText: i18n.t('components.default_layout.current_guest.greeting', {
        first_name: firstName,
      }),
    },
  }
}

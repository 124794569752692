import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { LOCALE } from '~/config'
import NotFound from '~/pages/not-found'

export default function () {
  const { hash, pathname, search } = useLocation()
  const pathParts = pathname.split('/').filter(Boolean)

  if (LOCALE === pathParts[0]) {
    const stripped = pathParts.slice(1).join('/')

    return <Navigate to={`/${stripped}${search}${hash}`} replace />
  }

  return <NotFound />
}

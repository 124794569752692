import kountSDK from '@kount/kount-web-client-sdk'
import { useEffect } from 'react'

import { FRAUD_CHECK_CLIENT_ID, FRAUD_CHECK_ENVIRONMENT } from '~/config'
import useFeatureFlags from '~/hooks/use-feature-flags'

import type { Cart } from '~/types'

export default function FraudCheckService({ cart }: { cart: Cart }) {
  const { fraudCheckingDataCollectionEnabled } = useFeatureFlags()
  const kountConfig = {
    clientID: FRAUD_CHECK_CLIENT_ID,
    environment: FRAUD_CHECK_ENVIRONMENT,
    isSinglePageApp: false,
  }

  const initializeKount = () => {
    kountSDK(kountConfig, cart.fraudCheckIdentifier)
  }

  useEffect(() => {
    if (fraudCheckingDataCollectionEnabled && cart?.fraudCheckIdentifier) {
      initializeKount()
    }
  }, [cart?.fraudCheckIdentifier])

  return null
}

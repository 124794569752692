import React from 'react'

import Button from '~/components/button'
import Icon from '~/components/icon'
import { i18n } from '~/i18n'
import './index.scss'

export const LogInLink = () => (
  <Button
    className="login-link"
    type="unstyled"
    to={'/login?redirect_uri=' + window.location.pathname}
  >
    <Icon glyph="skiier" /> {i18n.t('links.log_in.text')}
  </Button>
)

import styled from 'styled-components'

import { Wrapper as ProfilePictureWrapper } from '~/components/profile-picture/profile-picture.styles'
import { color, font, device } from '~/styles/utils'

export const CurrentGuestWrapper = styled.div`
  display: flex;
  align-items: center;

  & ${ProfilePictureWrapper} {
    background-color: ${color('lightGrey')};
  }

  & .amp-profile-picture {
    height: 35px;
    width: 35px;
  }

  & .greeting {
    display: none;

    @media ${device('notMobile')} {
      display: inline-block;
      ${font('bold')};
      text-transform: uppercase;
      font-size: 16px;
      margin-left: 16.5px;
    }
  }
`

import styled from 'styled-components'

import Button from '~/components/button'

export const ContactUsButton = styled(Button).attrs({
  type: 'plain',
})`
  .amp-base &&&& {
    font-size: 17px;
  }
`

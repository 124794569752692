import React, { PureComponent } from 'react'

import Button from '~/components/button'
import CurrencyLocale from '~/components/currency-locale'
import facebookIcon from '~/graphics/circle-facebook.svg'
import instagramIcon from '~/graphics/circle-instagram.svg'
import twitterIcon from '~/graphics/circle-twitter.svg'

import MyAccountSubmenu from './my-account-submenu'

import type { Currency, Links, Locale } from '~/types'

export type NavSocialLink = {
  type: string
  to: string
}

type Props = {
  bottomLinks: Links
  collapsed: boolean
  currency: Currency
  links: Links
  locale: Locale
  localeLinks: Links
  socialLinks: NavSocialLink[]
  onClickLink: () => void
  onCurrencyLocaleClick: (() => void) | null | undefined
}

export default class Navigation extends PureComponent<Props> {
  render() {
    const {
      links,
      bottomLinks,
      localeLinks,
      socialLinks,
      collapsed,
      onClickLink,
    } = this.props
    const menuClasses = collapsed ? 'menu' : 'menu open'

    return (
      <nav className={menuClasses}>
        <div className="top-nav-section">
          <ul className="main-nav-links">
            {links.map((link, i) => {
              return (
                <li key={i}>
                  <Button type="unstyled" to={link.to} onClick={onClickLink}>
                    {link.text}
                  </Button>
                </li>
              )
            })}
          </ul>
          <MyAccountSubmenu onClickLink={onClickLink} />
        </div>
        <div className="bottom-nav-section">
          <ul className="bottom-nav-links">
            <li>
              <CurrencyLocale
                currency={this.props.currency}
                locale={this.props.locale}
                onClick={this.props.onCurrencyLocaleClick}
              />
            </li>
            <li>
              {localeLinks.map((link, i) => {
                return (
                  <span key={i}>
                    {i !== 0 && ' / '}
                    <Button type="unstyled" to={link.to}>
                      {link.text}
                    </Button>
                  </span>
                )
              })}
            </li>
            {bottomLinks.map((link, i) => {
              return (
                <li key={i}>
                  <Button type="unstyled" to={link.to}>
                    {link.text}
                  </Button>
                </li>
              )
            })}
          </ul>
          <ul className="social-nav-links">
            {socialLinks.map((link, i) => {
              if (link.type === 'twitter') {
                return (
                  <li key={i}>
                    <Button type="unstyled" to={link.to} target="_blank">
                      <img src={twitterIcon} alt="twitter" />
                    </Button>
                  </li>
                )
              }

              if (link.type === 'instagram') {
                return (
                  <li key={i}>
                    <Button type="unstyled" to={link.to} target="_blank">
                      <img src={instagramIcon} alt="instagram" />
                    </Button>
                  </li>
                )
              }

              if (link.type === 'facebook') {
                return (
                  <li key={i}>
                    <Button type="unstyled" to={link.to} target="_blank">
                      <img src={facebookIcon} alt="facebook" />
                    </Button>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </nav>
    )
  }
}

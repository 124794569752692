// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export function componentLoader(
  lazyComponent: (...args: Array<any>) => any,
  attemptsLeft: number,
): Promise<any> {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }

          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 1500)
      })
  })
}

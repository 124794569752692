import styled from 'styled-components'

import Banner from '~/components/banner'
import { device } from '~/styles/utils'

export const StickyBanner = styled(Banner)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;

  height: 81px;

  @media ${device('notMobile')} {
    height: 36px;
  }
`

export const DOMPadding = styled.div`
  height: 81px;

  @media ${device('notMobile')} {
    height: 36px;
  }
`

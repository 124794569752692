import { SkipToMainContent } from './default-structure.styles'

import classNames from 'classnames'

import type { ReactNode } from 'react'

import React, { Component } from 'react'

import './default-structure.scss'
import { i18n } from '~/i18n'

export type Props = {
  header?: ReactNode
  flashMessage?: ReactNode
  footer?: ReactNode
  children: ReactNode
  className?: string
  foodBev?: boolean
}

export default class DefaultStructure extends Component<Props> {
  render() {
    const { header, footer, children, className, foodBev } = this.props
    const classNameJoined = classNames(
      'amp-layout-default-structure',
      className,
    )
    return (
      <div className={classNameJoined}>
        <header className="single-header">
          <SkipToMainContent href="#scrolling-body">
            {i18n.t('components.default_structure.skip_to_main_content')}
          </SkipToMainContent>
          {header}
          {this.props.flashMessage}
        </header>
        <main id="scrolling-body" className="single-scroll">
          <section
            className={classNames('single-content', {
              'food-bev': foodBev,
            })}
          >
            {children}
          </section>
          <footer className="single-footer">{footer}</footer>
        </main>
      </div>
    )
  }
}

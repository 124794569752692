import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { loadProfileProducts } from '~/actions/profile-products'
import useMemoizedSelector from '~/hooks/use-memoized-selector'
import { getProfileProducts } from '~/selectors/profile-products-selector'
import { validForBenefits } from '~/utils/benefits'

import MyAccountSubmenu from './my-account-submenu.component'

import type { GuestProfile } from '~/types'

type SelectorProps = {
  profile: GuestProfile
  authenticated: boolean
  validForBenefits: boolean
}

const mapStateToProps = (state): SelectorProps => {
  return {
    profile: state.profile,
    authenticated: state.auth.authenticated,
    validForBenefits: getProfileProducts(state).some(validForBenefits),
  }
}

type Props = {
  onClickLink?: () => any
}

export default function MyAccountSubmenuContainer({ onClickLink }: Props) {
  const dispatch: (...args: Array<any>) => any = useDispatch()
  const selectorProps = useMemoizedSelector(mapStateToProps)

  useEffect(() => {
    if (selectorProps.authenticated) dispatch(loadProfileProducts())
  }, [selectorProps.authenticated])

  return <MyAccountSubmenu {...selectorProps} onClickLink={onClickLink} />
}

import React from 'react'

import ConditionalWrapper from '~/components/conditional-wrapper'
import DoubleBounceSpinner from '~/components/double-bounce-spinner'
import { DefaultTemplate } from '~/components/templates'

type Props = {
  hideTemplate?: boolean
}

export default function LazyLoadingFallback({ hideTemplate }: Props) {
  return (
    <ConditionalWrapper
      condition={!hideTemplate}
      wrapper={(children) => <DefaultTemplate>{children}</DefaultTemplate>}
    >
      <div>
        <DoubleBounceSpinner light />
      </div>
    </ConditionalWrapper>
  )
}

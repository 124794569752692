/**
 * Renders the auth error page.
 */
import React from 'react'

import Button from '~/components/button'
import LayoutSingle from '~/components/layouts/single'
import { DefaultTemplate } from '~/components/templates'
import { i18n } from '~/i18n'

const AuthFailure = () => (
  <DefaultTemplate>
    <LayoutSingle>
      <section className="not-found-message">
        <h1 className="not-found-message-title">
          {i18n.t('pages.auth_failure.failure_message')}
        </h1>
        <Button to="/myaccount" type="progress">
          {i18n.t('pages.auth_failure.try_again')}
        </Button>
      </section>
    </LayoutSingle>
  </DefaultTemplate>
)

export default AuthFailure

import React from 'react'

import { Banner, BannerContent } from './banner.styles'

import type { ReactNode } from 'react'

export type Props = {
  children: ReactNode
  msgType?: 'alert' | 'message' | 'info'
}

export default ({ children, msgType, ...otherProps }: Props) => {
  return (
    <Banner $msgtype={msgType || ''} {...otherProps}>
      <BannerContent>{children}</BannerContent>
    </Banner>
  )
}

import type { ReactNode } from 'react'

type Props = {
  condition: boolean
  wrapper: (...args: Array<any>) => any
  children: ReactNode
}

export default function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: Props) {
  return condition ? wrapper(children) : children
}

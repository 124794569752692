import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addFlashMessage } from '~/actions/flash-message'
import getAdminAPI from '~/admin/utils/api'
import { Title, Text, TextContent, Button, Left } from '~/components/banner'
import { IS_GUEST_IMPERSONATION_SESSION } from '~/config'
import { i18n } from '~/i18n'
import changeLocation from '~/utils/change-location'
import { reportGroupedError } from '~/utils/logger'

import { StickyBanner, DOMPadding } from './guest-impersonation-banner.styles'

import type { AppState } from '~/reducers'

const GuestImpersonationBanner = () => {
  const profileId = useSelector((state: AppState) => state.profile.id)
  const api = getAdminAPI()
  const dispatch = useDispatch()

  if (!IS_GUEST_IMPERSONATION_SESSION) return null

  const onEndSession = async () => {
    try {
      const response = await api.endGuestImpersonation()

      if (response.ok) {
        changeLocation(`/admin/profiles/${profileId}`)
      } else {
        dispatch(
          addFlashMessage(
            'error',
            i18n.t('components.guest_impersonation_banner.request_failure'),
          ),
        )
      }
    } catch (error) {
      reportGroupedError('GuestImpersonationBanner/onEndSession', error)
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.guest_impersonation_banner.request_failure'),
        ),
      )
    }
  }

  return (
    <>
      <StickyBanner>
        <Left>
          <TextContent>
            <Title>
              {i18n.t('components.guest_impersonation_banner.title')}
            </Title>
            <Text>{i18n.t('components.guest_impersonation_banner.body')}</Text>
            <Button onClick={onEndSession}>
              {i18n.t('components.guest_impersonation_banner.call_to_action')}
            </Button>
          </TextContent>
        </Left>
      </StickyBanner>
      {/*
        Since we're using position: fixed, the DOM does not allow for the height of the banner.
        We instead add a new empty element which leaves a space for the banner at the top.
      */}
      <DOMPadding />
    </>
  )
}

export default GuestImpersonationBanner

/**
 * Component to handle displaying pages which require authentication.
 *
 * Will check if the user is logged in, allowing access if they are or
 * redirecting if they are not.
 */
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { AuthError, authenticationFailed } from '~/actions/auth'
import { replace } from '~/actions/navigation'
import { loadProfile } from '~/actions/profile'
import FraudCheckService from '~/components/fraud-check-service'
import { UNCONFIRMED_PATHS } from '~/config'
import { useAppDispatch } from '~/hooks/use-app-dispatch'
import useMemoizedSelector from '~/hooks/use-memoized-selector'

import type { ReactNode } from 'react'
import type { Location } from '~/types'

type Props = {
  children: ReactNode
  location?: Location
}

function canAccessWithoutConfirmation(path: string) {
  return !!UNCONFIRMED_PATHS.find((unconfirmedPath) => {
    if (unconfirmedPath instanceof RegExp) {
      return unconfirmedPath.test(path)
    } else {
      return path === unconfirmedPath
    }
  })
}

function buildOauthFailureRedirectSearch(location) {
  const queryWithoutError = queryString.parse(location.search)
  delete queryWithoutError.error
  return `?redirect_uri=${location.pathname + `?${queryString.stringify(queryWithoutError)}`}`
}

function buildDefaultRedirectSearch(location) {
  return `?redirect_uri=${encodeURIComponent(
    encodeURIComponent(location.pathname + location.search),
  )}`
}

function oAuthErrorCode(location) {
  const error = queryString.parse(location.search).error
  if (!error) return undefined
  if (error === 'not_registered') return AuthError.SOCIAL_NOT_REGISTERED
  else return AuthError.UNKNOWN
}

function mapStateToProps(state) {
  const {
    auth: { authenticated },
    profile,
  } = state
  return {
    authenticated,
    isConfirmed: !!profile.confirmedAt,
    profileLoaded: !!profile.loaded,
    profileLoadFailed: !!profile.loadFailed,
  }
}

export default function Auth({ children }: Props) {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { profileLoaded, profileLoadFailed, isConfirmed, authenticated } =
    useMemoizedSelector(mapStateToProps)

  useEffect(() => {
    if (!profileLoaded && !profileLoadFailed) dispatch(loadProfile())
  }, [])

  useEffect(() => {
    redirectIfNecessary()
  }, [
    profileLoaded,
    profileLoadFailed,
    authenticated,
    isConfirmed,
    location.pathname,
  ])

  const redirectIfNecessary = () => {
    if (!profileLoaded && !profileLoadFailed) return

    if (!authenticated) {
      let search = ''

      if (location.pathname !== '/logout') {
        if (oAuthErrorCode(location)) {
          dispatch(authenticationFailed(oAuthErrorCode(location)))
          search = buildOauthFailureRedirectSearch(location)
        } else {
          search = buildDefaultRedirectSearch(location)
        }
      }

      dispatch(replace('/login' + search))
    } else if (
      !isConfirmed &&
      !canAccessWithoutConfirmation(location.pathname)
    ) {
      dispatch(replace('/register/confirm'))
    }
  }

  const willRedirect =
    !authenticated ||
    (!isConfirmed && !canAccessWithoutConfirmation(location.pathname))

  if (!profileLoaded && !profileLoadFailed) return null
  if (willRedirect) return null

  return (
    <>
      <FraudCheckService />
      {children}
    </>
  )
}

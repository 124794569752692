import classNames from 'classnames'
import React, { Component } from 'react'

import type { ReactNode } from 'react'

import './single.scss'

export type Props = {
  children?: ReactNode
  className?: string
}

export default class Single extends Component<Props> {
  render() {
    const { children, className } = this.props
    return (
      <div className={classNames('amp-layout-single', className)}>
        {children}
      </div>
    )
  }
}

import { connect } from 'react-redux'

import { onFlashMessageRemove } from '~/actions/flash-message'

import Default from './default.component'

import type { ActionProps } from './default.types'
import type { AppState } from '../../reducers'
import type { Dispatch } from 'redux'
import type { FlashMessage } from '~/actions/flash-message'
import type { AppActions } from '~/actions/types'

type OwnProps = {
  flashMessages?: FlashMessage[]
}

function mapStateToProps(state: AppState, ownProps: OwnProps) {
  const ownFlashMessages = ownProps.flashMessages || []
  return {
    flashMessages: [...state.flashMessages, ...ownFlashMessages],
    profile: state.profile,
    authenticated: state.auth.authenticated,
    cart: state.cart,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>): ActionProps => ({
  onFlashMessageRemove: (message: FlashMessage) =>
    dispatch(onFlashMessageRemove(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Default)
